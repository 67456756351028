import React from 'react';
import { VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@material-ui/icons/School"
import WorkIcon from '@mui/icons-material/Work';

function Experience() {
  return (
    <div className='experince'> 
    
    <VerticalTimeline lineColor="#3e497a">
    
      <VerticalTimelineElement className="vertical-timeline-element--education"
      date="September 2019 - June 2023"
      iconStyle={ {background: "#3e497a", color: "#fff"}}
      icon={<SchoolIcon />} 
      >

      <h3 className='vertical-timeline-element-title'>Thornhill Secondary School, Thornhill, Ontario</h3>
      <p>High School Diploma</p>
      </VerticalTimelineElement>

      <VerticalTimelineElement className="vertical-timeline-element--work"
      date="June 2019 - April 2023"
      iconStyle={ {background: "#3e497a", color: "#fff"}}
      icon={<WorkIcon />} 
      >

      <h3 className='vertical-timeline-element-title'>Canadian Centre for Victims of Torture: CCVT, Remote</h3>
      <h4 className='vertical-timeline-element-subtitle'>Tutor / Summer Camp Counselor</h4>
      </VerticalTimelineElement>

      <VerticalTimelineElement className="vertical-timeline-element--work"
      date="June 2021 - September 2021"
      iconStyle={ {background: "#3e497a", color: "#fff"}}
      icon={<WorkIcon />} 
      >

      <h3 className='vertical-timeline-element-title'>Vortex Fire Protection, Toronto</h3>
      <h4 className='vertical-timeline-element-subtitle'>Full Stack Software Engineer</h4>
      </VerticalTimelineElement>

      
      <VerticalTimelineElement className="vertical-timeline-element--work"
      date="December 2021 - May 2023"
      iconStyle={ {background: "#3e497a", color: "#fff"}}
      icon={<WorkIcon />}
      >

      <h3 className='vertical-timeline-element-title'>Kurius Non-Profit, Remote</h3>
      <h4 className='vertical-timeline-element-subtitle'>Developer</h4>
      </VerticalTimelineElement>

      <VerticalTimelineElement className="vertical-timeline-element--work"
      date="March 2022 - December 2022"
      iconStyle={ {background: "#3e497a", color: "#fff"}}
      icon={<WorkIcon />} 
      >

      <h3 className='vertical-timeline-element-title'>Expand Youth Society, Remote</h3>
      <h4 className='vertical-timeline-element-subtitle'>Chief Technology Officer</h4>
      </VerticalTimelineElement>

      <VerticalTimelineElement className="vertical-timeline-element--education"
      date="September 2023 - April 2028"
      iconStyle={ {background: "#3e497a", color: "#fff"}}
      icon={<SchoolIcon />} 
      >

      <h3 className='vertical-timeline-element-title'>University of Waterloo, Kitchener, Ontario</h3>
      <h4 className='vertical-timeline-element-subtitle'>Bachelor's Degree</h4>
      <p>Electrical Engineering</p>
      </VerticalTimelineElement>
      
      <VerticalTimelineElement className="vertical-timeline-element--work"
      date="January 2024 - April 2024"
      iconStyle={ {background: "#3e497a", color: "#fff"}}
      icon={<WorkIcon />} 
      >

      <h3 className='vertical-timeline-element-title'>Crosslinx Transit Solutions, Toronto</h3>
      <h4 className='vertical-timeline-element-subtitle'>Systems Engineer</h4>
      </VerticalTimelineElement>


    
    </VerticalTimeline>
    
    </div>
  )
}

export default Experience