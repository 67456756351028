import React from 'react'
import { useParams } from 'react-router-dom';
import { ProjectList } from '../helpers/ProjectList';
import GithubIcon from "@material-ui/icons/GitHub"
import "../styles/ProjectDisplay.css";

function ProjectDisplay() {
    const { id } = useParams();
    const project = ProjectList[id];
    return (
    
    
    <div className="project"> 
    <h1> {project.name} </h1>
    <img src={project.image} height={325}/>
    <p>
        <b>Description:</b> {project.description}
    </p>
    <p>
        <b>Skills:</b> {project.skills}
    </p>
    <p>
         <b>Github : </b> <GithubIcon onClick={() => window.open(project.link)} />
    </p> 
    

    <p>
        <b>{project.devpost}</b> {project.icon}
    </p>

    </div>
    );
}

export default ProjectDisplay