import React from 'react'
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GithubIcon from '@material-ui/icons/GitHub'
import EmailIcon from '@material-ui/icons/Email'

import "../styles/Footer.css"

function Footer() {
  return (
    <div className="footer">
        <div className="socialMedia">
        <LinkedInIcon onClick={() => window.open("https://www.linkedin.com/in/ryan-sadeghi-javid-a1073a15a/")} />
        <EmailIcon onClick={() => window.location = 'mailto:javidryan7@gmail.com'}/>
        <GithubIcon onClick={() => window.open("https://github.com/Ryan-Sadeghi-Javid")} />
        </div>
        <p> &copy; 2023 ryanjavid.com</p>
    </div>
  )
}

export default Footer