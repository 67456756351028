import java_physics_engine_image from '../assets/java_physics_engine_image.PNG'
import waterpunk_image from '../assets/waterpunk_image.PNG'
import healic_image from '../assets/healic_image.png'
import arduinkler_image from '../assets/arduinkler_image.jpg'
import sudoku_solver_image from '../assets/sudoku_solver_image.PNG'
import TerminalIcon from '@mui/icons-material/Terminal';

export const ProjectList = [
    {
        name: "Java Physics Engine",
        image: java_physics_engine_image,
        description: "This project features a Java-based physics engine built on object-oriented principles, using the Mario game to illustrate its user-friendliness and adaptability to fellow developers.",
        skills: "Java, JavaFX.",
        link: "https://github.com/Ryan-Sadeghi-Javid/ICS4UFinalProject"
    },
    {
        name: "Waterpunk 2022: C++ video game",
        image: waterpunk_image,
        description: "WaterPunk 2022 is a 2D multiplayer shooter game developed during a hackathon. It delivers an immersive gaming experience, meticulously crafted using C++17 and SDL2, with custom-built physics at its core.",
        skills: "C++, SDL2, SDL2_image, SDL2_net",
        devpost: <b>Devpost :         </b>,
        link: "https://github.com/waterpunk-2022",
        icon: <TerminalIcon onClick={() => window.open("https://devpost.com/software/waterpunk-2022")} />
    },
    {
        name: "Healic: Match and chat platform",
        image: healic_image,
        description: "Healic is a web app designed to connect individuals facing various mental health challenges, fostering anonymous peer-to-peer conversations and providing resources for learning and charitable contributions.",
        skills: "React.js, materializecss, Node.js, express.js, socket.io",
        link: "https://github.com/Ryan-Sadeghi-Javid/nextstep-hacks",
        devpost: <b>Devpost :         </b>,
        icon: <TerminalIcon onClick={() => window.open("https://devpost.com/software/healic")} />
    },
    {
        name: "Arduinkler: Remote watering system",
        image: arduinkler_image,
        description: "Arduinkler is a smart gardening project that employs a water sensor and Arduino board to monitor soil moisture levels. It automatically waters plants when moisture falls below a preset threshold and provides real-time data on a web app, allowing remote monitoring and manual watering.",
        skills: "C++ (Arduino interface), TypeScript (Arduino wrapper), TypeScript, Express.js (API wrapper), React, TypeScript.",
        link: "https://github.com/Ryan-Sadeghi-Javid/bayview-hackathon-api",
        devpost: <b>Devpost :         </b>,
        icon: <TerminalIcon onClick={() => window.open("https://devpost.com/software/arduinkler")} />
    },
    {
        name: "Sudoku Solver",
        image: sudoku_solver_image,
        description: "This sudoku solver uses a recursive backtracking algorithm to efficiently solve any sudoku board given that it contains the minimum amount of required data.",
        skills: "Java, Java Swing",
        link: "https://github.com/Ryan-Sadeghi-Javid/SudokuSolver"
    }
]